@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Nanum Gothic', sans-serif;
}
#root {
    width: 100%;
    height: 100%;
    background: #f2f2f2;
}

ul {
    list-style: none;
}
a {
    text-decoration: none;
}
img {
    width: 100%;
}
.adminWrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
pre {
    background-color: #ccc;
    color: #000;
    border: 1px solid #aaa;
    overflow: visible;
    padding: 10px;
    border-radius: 5px;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@page {
    size: A4;
    margin: 0;
    /* // size: landscape; */
}

@media print {
    /* .adminWrap {
        display: none;
    } */
    html,
    body {
        width: 210mm;
        height: 297mm;
        /* background: #fff; */
        margin: 0;
        padding: 0;
        page-break-after: avoid;
        page-break-before: avoid;
    }
    .page:first-child {
        page-break-before: auto;
        page-break-inside: avoid;
    }
    .page {
        width: 210mm;
        /* height: 297mm; 서브픽셀 해결1 사용시 */
        box-shadow: 0;
        margin: 0;
        margin-top: -1px;
        overflow: hidden;
        /* position: relative; */
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}
.kakaoBtn {
    height: 30px;
    line-height: 30px;
    display: inline-block;
}
.kakaoBtn::before {
    display: inline-block;
    position: relative;
    top: 0px;
    width: 30px;
    height: 30px;
    background: url(./styles/img/bg_social.png) no-repeat;
    background-size: 60px 30px;
    vertical-align: top;
    content: '';
}
.btnWrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.btnWrap button {
    width: 10%;
    height: 40px;
    background: rgb(0, 128, 255);
    border: none;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    min-width: 64px;
}
.btnWrap button:disabled {
    background: #ccc;
}
.btnWrap p {
    width: 100%;
    text-align: center;
    margin: 0.5rem 0;
    letter-spacing: -1px;
    color: #333;
    font-weight: 600;
}
.btn {
    display: flex;
    justify-content: space-between;
    padding: 0.4rem 0.5rem 0.7rem 0.5rem;
    box-sizing: border-box;
    font-weight: 600;
}
.btn p {
    cursor: pointer;
    letter-spacing: -1.3px;
    font-size: 15px;
}

.popupImg {
    min-width: 300px;
    max-width: 600px;
}
